import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useCallbackRef } from './use-callback-ref';

type UseControllableStateProps<TState> = {
  /** value to be used in controlled mode */
  value?: TState;
  /** initial value to be used in uncontrolled mode */
  defaultValue?: TState;
  /** callback for value changes */
  onChange?: (state: TState) => unknown;
};

/**
 * React hook to support both controlled/uncontrolled modes
 * @see https://reactjs.org/docs/uncontrolled-components.html
 */
export const useControllableState = <TState,>({
  value,
  defaultValue,
  onChange,
}: UseControllableStateProps<TState>): [
  TState,
  Dispatch<SetStateAction<TState>>,
] => {
  const [uncontrolledState, setUncontrolledState] = useState(
    defaultValue as TState,
  );
  const prevValueRef = useRef(uncontrolledState);
  const onChangeState = useCallbackRef(onChange);

  const isControlled = value !== undefined;
  const state = isControlled ? value : uncontrolledState;

  const setState = useCallback<Dispatch<SetStateAction<TState>>>(
    (nextValue) => {
      if (isControlled) {
        const nextState =
          nextValue instanceof Function ? nextValue(value) : nextValue;

        if (value === nextState) {
          return;
        }
        onChangeState(nextState as TState);
      } else {
        setUncontrolledState(nextValue);
      }
    },
    [isControlled, onChangeState, value],
  );

  useEffect(() => {
    if (!isControlled && prevValueRef.current !== uncontrolledState) {
      onChangeState(uncontrolledState);
      prevValueRef.current = uncontrolledState;
    }
  }, [isControlled, onChangeState, uncontrolledState]);

  return [state, setState];
};
